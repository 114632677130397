.SubCard-container {
  font-weight: bold;
  background-color: #d3eaff;
  color: #111042;
  // height: 70vh;
  max-height: 400px;
  // min-height: 200px;
  min-height: 50px;
  max-width: 280px;
  // min-width: 150px;
  min-width: 50px;
  // width: 60vw;
  // box-shadow: inset 5px 5px 5px rgb(5, 9, 27);
  padding: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: 150ms ease-in;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media (max-width: 415px) {
    //phone
    padding: 0.8em;
    font-size: 0.8rem;
    height: 220px;
    width: 150px;
  }
  @media (max-width: 810px) {
    //tab
    width: 220px;
    height: 270px;
    padding: 0.8em;
    font-size: 0.8rem;
  }
  .plan-type {
    font-size: 1rem;
    margin-bottom: 0.2em;
    @media (max-width: 40em) {
      //phone
      font-size: 0.6rem;
    }
  }
  .original-price {
    font-size: 1rem;
    opacity: 0.6;
    text-decoration: line-through;
    margin-bottom: 0.2em;

    @media (max-width: 40em) {
      //phone
      font-size: 0.6rem;
    }
  }
  .price-duration {
    background-color: #514fc45b;

    height: 3.2em;
    width: 3.2em;
    border-radius: 50%;
    font-size: 2rem;
    color: #18174a;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 40em) {
      //phone
      font-size: 1rem;
    }
  }
  .duration {
    font-size: 0.8rem;
    color: #18174a;
    @media (max-width: 40em) {
      //phone
      font-size: 0.6rem;
    }
  }
  .plan-text {
    @media (max-width: 40em) {
      //phone
      font-size: 0.6rem;
    }
  }
  .subCard-btn {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    @media (max-width: 415px) {
      //phone
      width: 20px;
    }
  }
  &:hover {
    scale: 1.05;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.6);
  }
}
