@keyframes leftIn {
  0% {
    right: -200px;
  }
  100% {
    right: 0px;
  }
}
.arrow-menu-container {
  border-radius: 0.6em;
  animation-name: leftIn;
  animation-duration: 500ms;
  transition: 250ms all ease-in-out;
  position: fixed;
  top: 8%;
  right: 2%;
  height: 5em;
  width: 30%;
  background-color: #3d3c76;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  z-index: 20;

  .arrow-menu-links {
    cursor: pointer;
  }
}
