.identify {
  .identify-cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 1em auto 8em;
    @media (max-width: 1465px) {
      //phone
      display: grid;
      justify-content: center;
      align-content: center;
      margin-bottom: 3em;
    }
    .identify-cards {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media (max-width: 1465px) {
        //phone
        display: grid;
        justify-content: center;
        align-content: center;
        gap: 3em;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }
      @media (max-width: 415px) {
        //phone
        display: grid;
        justify-content: center;
        align-content: center;
        gap: 0.5em;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }
    }
  }
}
