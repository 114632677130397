.app-sidebar {
  // width: 3em;

  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: #18174a;
  height: 100vh;
  min-height: 100vh;
  z-index: 10;
  @media (max-width: 1200px) {
    //phone
    display: none;
  }

  .app-sidebar-logo-container {
    width: 3em;
    height: 5.5em;
    display: flex;
    flex: 0.5;
    border-radius: 50%;
    justify-content: center;
    align-items: flex-start;
    margin-top: 1.6em;
    .app-sidebar-logo {
      width: 2em;
      height: 2em;
      cursor: pointer;
    }
  }
  .app-sidebar-buttons-features {
    margin-top: 1em;
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .app-sidebar-buttons-pages {
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 2em;
      @media (max-height: 630px) {
        //phone
        gap: 1em;
      }
      .app-sidebar-buttons-features-box {
        height: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        border-radius: 0.2em;
        cursor: pointer;
        // background-color: #e5c6ff;
        color: #d3eaff;
        @media (max-height: 630px) {
          //phone
          font-size: 1rem;
        }
        &:hover .sidebar-hover-state {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          z-index: 10;
          left: 35px;
          // background-color: #d3eaff;
          font-size: 0.8rem;
          font-weight: bold;
          color: #3d3c76;
          padding: 0.5em;
          border-radius: 0.3em;
          white-space: nowrap;
          opacity: 1;
          width: auto;
          transition: 300ms all ease-out;
          // transform: scale(1);
          box-shadow: inset 10em 0em #d3eaff;
          transform: translateX(10px);
        }
        .sidebar-hover-state {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          z-index: 10;
          left: 35px;
          // background-color: #d3eaff;
          font-size: 0.8rem;
          font-weight: bold;
          color: #3d3c76;
          padding: 0.5em;
          border-radius: 0.3em;
          white-space: nowrap;
          opacity: 0;
          width: 0;
          // transform: scale(0);
          z-index: 100;
          box-shadow: inset 0em 0em #d3eaff;
          transform: translateX(0);
        }
      }

      .app-sidebar-buttons-features-box-true {
        height: 1em;
        width: 1em;
        scale: 1.1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        border-radius: 0.2em;
        cursor: pointer;
        transition: 250ms all ease-in-out;
        color: rgb(255, 229, 83);
      }
    }
    .app-sidebar-buttons-socials {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 2em;
      // padding-bottom: 1.5em;
      @media (max-height: 630px) {
        //phone
        gap: 1em;
      }
      .app-sidebar-buttons-socials-box {
        font-size: 1.6rem;
        @media (max-height: 630px) {
          //phone
          font-size: 1rem;
        }
      }
    }

    .logout {
      color: #ff6275;
      height: 1em;
      width: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      border-radius: 0.2em;
      cursor: pointer;
      transition: 250ms all ease-in-out;
      margin-bottom: 1em;
      @media (max-height: 630px) {
        //phone
        font-size: 1rem;
      }
    }
    .logout-popup-active {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #d3eaff;
      position: absolute;
      bottom: 1%;
      left: 4em;
      padding: 1em;
      width: 150px;
      border-radius: 0.6em;
      color: #18174a;
      transition: 250ms ease-in-out;
      z-index: 10;
      button {
        height: 3em;
        width: 7em;
        padding: 1em;
      }
    }
    .logout-popup-inactive {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      bottom: 1%;
      left: 2%;
      transform: scale(0);
      width: 150px;
      transition: 250ms ease-in-out;
      button {
        height: 3em;
        width: 7em;
        padding: 1em;
      }
    }
  }
}
