.history-options-order-flow-container {
  height: 100%;

  @media (max-width: 1200px) {
  }

  .history-oof-header {
    background-color: #18174a;
    border-radius: 0.6em 0.6em 0 0;
    margin: 0 auto;
    min-width: 740px;
    // height: 500px;
    min-height: 500px;
    height: 75vh;
    width: 97.5%;

    @media (max-width: 1200px) {
      min-width: auto;
      height: 92vh;
    }

    .history-oof-textheading {
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      height: 4em;
      color: #66ffda;
    }
    .mobile-home-links {
      display: flex;
      justify-content: space-between;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        min-height: 40px;
        flex: 1;
        font-weight: 400;
        font-size: 0.5rem;
        border-radius: 0.6em 0.6em 0 0;
        cursor: pointer;
        text-align: center;
      }
      .linkActive {
        color: #66ffda;
        background-color: #090833;
      }
      .linkInactive {
        color: #66ffd959;
      }
    }
    .history-oof-table {
      font-size: 0.7rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-weight: bold;
      height: 2em;
      background-color: #18174a;
      border-radius: 0.6em 0.6em 0 0;

      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: #18174a #18174a;
      scroll-behavior: smooth;
      transition: scrollbar-color 100ms ease-in;

      &:hover {
        overflow-y: scroll;
      }
      @media (max-width: 1200px) {
        //phone
        display: none;
      }
      .cyanTxt {
        color: #66ffda;
      }
      .greenTxt {
        color: #a9ff66;
      }
      .redTxt {
        color: #ff6275;
      }
      .flex1 {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .history-oof-main-tiles {
      // background-color: #090833;
      // max-height: 650px;
      height: calc(100% - 86.4px);

      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: #090833 #090833;
      scroll-behavior: smooth;
      transition: scrollbar-color 100ms ease-in;
      @media (max-width: 1200px) {
        //phone

        height: calc(100% - 64px);
      }
      &:hover {
        overflow-y: scroll;
        scrollbar-color: #4d4d7e #090833;
      }
      &.loading_container {
        display: flex;
        width: 100%;
        height: 80%; // change for responsiveness
        justify-content: center;
        align-items: center;
      }

      .history-oof-tile-background {
        background-color: #090833;
      }
    }
  }
}
