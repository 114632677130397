@keyframes scaleUp {
  0% {
    scale: 0;
  }
  65% {
    scale: 1.04;
  }
  100% {
    scale: 1;
  }
}
.gift-pop-up-container {
  //   transform: scale(0.8);
  animation-name: scaleUp;
  animation-duration: 0.5s;
  transition: 250ms all ease-in-out;
  max-height: 600px;
  min-height: 300px;
  max-width: 450px;
  min-width: 450px;

  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #3d3c76;
  padding: 2em;
  border-radius: 0.6em;
  @media (max-width: 500px) {
    //phone

    max-width: 300px;
    min-width: 300px;
  }
  .gift-header {
  }
  .info-icon {
    font-size: 2rem;
    @media (max-width: 500px) {
      //phone
      font-size: 1.5rem;
    }
  }
  .gift-feature {
    font-size: 0.8rem;
    @media (max-width: 500px) {
      //phone
      font-size: 0.6rem;
    }
  }
}
