.text-container {
  width: 80%;

  .text-heading {
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1.2;
    color: #b7eeff;
    margin-bottom: 0.5em;

    @media (max-width: 70em) {
      //phone
      font-size: 2rem;
      text-align: center;
    }
  }
  .text-subheading {
    font-size: 1.4rem;
    margin-bottom: 1.2em;
    font-weight: 500;
    @media (max-width: 70em) {
      //phone
      font-size: 0.8rem;
      text-align: center;
    }
  }
}
