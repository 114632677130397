@keyframes scaleUp {
  0% {
    scale: 0;
  }
  65% {
    scale: 1.04;
  }
  100% {
    scale: 1;
  }
}
@keyframes rotate {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  100% {
    transform: rotateY(360deg);
    opacity: 1;
  }
}
.login-container-first {
  display: block;

  // animation-name: scaleUp;
  // animation-duration: 0.5s;
  // transition: 250ms all ease-in-out;
  &.first {
    // transform: rotateY(180deg);
    animation-name: scaleUp;
    animation-duration: 0.5s;
    transition: 250ms all ease-in-out;
  }
  &.notfirst {
    animation-name: rotate;
    transform: rotateY(360deg);
    animation-duration: 0.5s;
    transition: 250ms all ease-in-out;
  }
  .login {
    // height: 70vh;
    // max-height: 600px;
    // min-height: 600px;
    // max-width: 370px;
    // min-width: 300px;
    // width: 60vw;
    background-color: #3d3c76;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 2.5em;
    color: #edfbff;

    .login-title {
      text-decoration: none;
      font-weight: bold;
      font-size: 2rem;
      height: 1.5em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .phone-no {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1.6em 0;
      label {
        font-size: 1rem;
        display: flex;
        text-align: center;
      }
    }
    .phone-no-input {
      height: 4em;
      width: 80%;
      margin-top: 1.2em;
      border-radius: 5px;
      border: none;
      background-color: #d4d9ff;
    }
    .login-btn-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        width: 150px;
        margin-bottom: 1.2em;
      }
      .or-use-phone {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .forgot-password {
      cursor: pointer;
      display: flex;
      width: 85%;
      justify-content: flex-end;
      font-size: 0.6rem;
      margin: 1em auto;
    }
    .extras {
      display: flex;

      flex-direction: column;
      justify-content: flex-start;
      align-items: space-evenly;
    }

    .forgot-password {
      margin-bottom: 2em;
    }
    .forgot-password-text,
    .if-purchased {
      font-size: 0.8rem;
      text-align: center;
      .register-here {
        cursor: pointer;
        font-weight: bold;
      }
    }

    .bundle-package {
      font-size: 1.5rem;
      font-weight: 600;
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      .bundle-package-arrow {
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 150ms all ease-in;
      }
      .bundle-package-arrow:hover {
        scale: 1.2;
      }
    }
  }
}
