.watchlist-tile-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #090833;
  //   border-radius: 0.6em;
  height: 3em;
  font-weight: bold;
  font-size: 0.7rem;
  width: 100%;

  .flex1 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ticker {
    display: flex;
    justify-content: center;
    background-color: #77ffbe;
    color: #345646;
    padding: 0.2em 0;
    border-radius: 0.6em;
    width: 4em;
    flex-shrink: 1;
  }

  .watchlistDel {
    cursor: pointer;

    .watchlist-minus {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.6em;
      font-size: 1rem;
      height: 1em;
      width: 1em;
      background-color: #d8396e;
      color: #220000;
    }
    .watchlist-plus {
      display: flex;
      justify-content: center;
      align-items: center;
      color: cornflowerblue;
      border-radius: 0.6em;
      font-size: 1rem;
      height: 1em;
      width: 1em;
      background-color: #ffffff;
    }
  }
}
