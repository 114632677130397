@use "../../../styles" as *;

@keyframes goldAnim {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100vw);
    opacity: 0;
  }
}
@keyframes sweepAnim {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.oof-tile-container {
  // position: relative;
  animation-name: sweepAnim;
  animation-duration: 1s;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #090833;
  //   border-radius: 0.6em;
  height: 3em;
  font-weight: bold;
  font-size: 0.7rem;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 0;
  .gold-sweep {
    height: 100%;
    width: 10em;
    left: 0;
    position: absolute;
    z-index: -10;
    opacity: 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      white,
      yellow,
      white,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );

    animation-name: goldAnim;
    animation-duration: 1s;
    animation-iteration-count: 1;
    transition: 1000ms all ease-in-out;
  }
  &.oof-tile-gold {
    background-image: linear-gradient(
      rgba(255, 255, 0, 0),
      rgba(255, 255, 0, 0.01),
      rgba(255, 255, 0, 0.1),
      rgba(255, 255, 0, 0.15),
      rgba(255, 255, 0, 0.25),
      rgba(255, 255, 0, 0.35),
      rgba(255, 255, 0, 0.4)
    );
    filter: hue-rotate(-20deg);
  }

  .flex1 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .green-ticker {
    display: flex;
    justify-content: center;
    // background-color: #77ffbe;
    // color: #345646;
    color: white;
    padding: 0.2em 0;
    border-radius: 5px;
    width: 4em;
    flex-shrink: 1;
    border: 2px solid #77ffbe;
    letter-spacing: 0.1em;
  }
  .blue-ticker {
    display: flex;
    justify-content: center;
    background-color: #00eeff;
    color: rgb(18, 90, 89);
    color: white;
    padding: 0.2em 0;
    border-radius: 0.6em;
    width: 4em;
    flex-shrink: 1;
    letter-spacing: 0.1em;
  }
  .red-ticker {
    display: flex;
    justify-content: center;
    border: 2px solid $base-red;
    // background-color: $base-red;
    // color: #590000;
    color: white;
    padding: 0.2em 0;
    border-radius: 5px;
    width: 4em;
    flex-shrink: 1;
    letter-spacing: 0.1em;
  }
  .green-item {
    color: $base-green;
  }
  .red-item {
    color: $base-red;
  }
  .smaller {
    font-weight: bold;
    font-size: 0.6rem;
    text-align: center;
  }
  @media (max-width: 1200px) {
    //phone
    display: none;
  }
}

.oof-tile-container-mobile {
  padding: 10px 1em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  display: flex;
  // justify-content: flex-start;
  // align-items: flex-start;
  background-color: #090833;
  //   border-radius: 0.6em;
  height: 7em;
  font-weight: bold;
  font-size: 0.7rem;
  .oof-tile-container-rearranged {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
  }
  .gold-sweep {
    height: 100%;
    width: 10em;
    left: 0;
    position: absolute;
    opacity: 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      white,
      yellow,
      white,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );

    animation-name: goldAnim;
    animation-duration: 1s;
    animation-iteration-count: 1;
    transition: 1000ms all ease-in-out;
  }
  &.oof-tile-gold {
    background-image: linear-gradient(
      rgba(255, 255, 0, 0),
      rgba(255, 255, 0, 0.01),
      rgba(255, 255, 0, 0.1),
      rgba(255, 255, 0, 0.15),
      rgba(255, 255, 0, 0.25),
      rgba(255, 255, 0, 0.35),
      rgba(255, 255, 0, 0.4)
    );
    // background-image: linear-gradient(
    //   rgba(255, 255, 0, 0),
    //   rgba(255, 255, 0, 0.01),
    //   rgba(255, 0, 242, 0.1),
    //   rgba(255, 0, 242, 0.15),
    //   rgba(255, 0, 242, 0.25),
    //   rgba(255, 0, 242, 0.35),
    //   rgba(255, 0, 242, 0.4)
    // );
    filter: hue-rotate(-20deg);
  }
  .gap {
    display: flex;
    gap: 0.5em;
  }
  .mobile-table-header {
    font-weight: normal;
    font-size: 0.5rem;
    opacity: 0.6;
  }
  .flex1 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .green-ticker {
    display: flex;
    justify-content: center;
    // background-color: #77ffbe;
    // color: #345646;
    color: white;
    padding: 0.2em 0;
    border-radius: 5px;
    width: 4em;
    flex-shrink: 1;
    border: 2px solid #77ffbe;
    letter-spacing: 0.1em;
  }
  .blue-ticker {
    display: flex;
    justify-content: center;
    background-color: #00eeff;
    color: rgb(18, 90, 89);
    color: white;
    padding: 0.2em 0;
    border-radius: 0.6em;
    width: 4em;
    flex-shrink: 1;
    letter-spacing: 0.1em;
  }
  .red-ticker {
    display: flex;
    justify-content: center;
    border: 2px solid $base-red;
    // background-color: $base-red;
    // color: #590000;
    color: white;
    padding: 0.2em 0;
    border-radius: 5px;
    width: 4em;
    flex-shrink: 1;
    letter-spacing: 0.1em;
  }
  .green-item {
    color: $base-green;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
  }
  .red-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $base-red;
    font-size: 0.8rem;
  }
  .smaller {
    font-weight: bold;
    font-size: 0.6rem;
    text-align: center;
  }
  @media (min-width: 1201px) {
    //phone
    display: none;
  }
}
