.slider-desktop {
  display: block;
  width: 80%;
  margin: 0 auto;
  text-align: left;
  @media (max-width: 650px) {
    //phone
    display: none;
  }
}
.slider-mobile {
  display: none;
  text-align: left;
  @media (max-width: 650px) {
    //phone
    display: block;
  }
}
.carousel-container {
  margin: 2em auto;
  width: 80%;
  overflow: hidden;
  font-weight: 500;
  @media (max-width: 810px) {
    //phone
    font-size: 00.8rem;
  }
  .client-testimonial {
    margin-left: 0.6em;
  }
  cursor: pointer;
  .carousel {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center; // gap: 2em;
    .client-card {
      border-radius: 0.6em;

      height: 300px;
      padding: 2em;
      display: flex;
      flex-direction: column;
      background-color: #5c5aca;
      @media (max-width: 430px) {
        //phone
        height: 220px;
      }
      .client-heading-container {
        display: flex;
        align-items: center;
        margin: 0 0 0.5em 0;
        .client-image {
          margin-right: 1em;
          height: 4em;
          width: 4em;
          border-radius: 100%;
        }
        .client-name {
          font-size: 1.2rem;
          font-weight: bold;

          .client-profession {
            font-weight: normal;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
