.app-container {
  height: 100%;
  background-color: #3d3c76;
  width: 100%;
  // min-height: 900px;
  .wallstflow-app {
    display: flex;
    height: 100%;
    .app-main {
      width: 100vw;
      height: auto;
      @media (max-width: 850px) {
      }
      .app-main-leftandright-container {
        display: flex;
        // height: auto;
        // justify-content: space-between;
        .app-main-left-container {
          width: 100%;
          height: 100%;
          flex: 1;

          .app-main-cards-container {
            display: flex;
            justify-content: space-between;
            margin-right: 1em;
            flex: 1;
            // width: 100%;
            gap: 0.5rem;

            // max-height: 600px;
            // min-height: 70px;
            // max-width: 370px;
            // min-width: 300px;
            // width: 60vw;
            &.loading_container {
              display: flex;

              flex: 1;
              margin: 0 auto;
              width: 97.4%;
              height: 140px; // change for responsiveness
              justify-content: center;
              align-items: center;
              background-color: #18174a;
              border-radius: 0.6em;
              @media (max-width: 1000px) {
                //phone
                display: none;
              }
            }
            @media (max-width: 1000px) {
              //phone
              display: none;
            }
          }
        }
        .app-main-right-container {
          display: flex;
          flex-direction: column;

          width: 20%;
          gap: 1em;

          @media (max-width: 1200px) {
            //phone
            display: none;
          }
          .ed-container-home {
            width: 95%;
            flex: 1;
            @media (max-width: 1200px) {
              //phone
              display: none;
            }
          }
          .ai-container-home {
            width: 95%;
            flex: 1;
            @media (max-width: 1200px) {
              //phone
              display: none;
            }
          }
        }
      }
    }
  }
}
