.login-secret-container {
  opacity: 0;
  transform: rotateY(180deg);

  .login-secret {
    height: 50vh;
    max-height: 600px;
    min-height: 400px;
    max-width: 370px;
    min-width: 300px;
    width: 60vw;
    background-color: #3d3c76;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 2.5em;
    color: #edfbff;

    .login-title {
      text-decoration: none;
      font-weight: bold;
      font-size: 2rem;
      height: 1.5em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5em;
    }

    .login-btn-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      button {
        width: 20vh;
        margin-bottom: 1.2em;
      }
      .or-use-email {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 1.2em;
      }
      .or-use-phone {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 1.2em;
      }
    }
    .forgot-password {
      cursor: pointer;
      display: flex;
      width: 85%;
      justify-content: flex-end;
      font-size: 0.6rem;
      margin: 1em auto;
    }
    .extras {
      display: flex;
      height: 20vh;
      flex-direction: column;
      justify-content: flex-start;
      align-items: space-evenly;
    }

    .forgot-password-text,
    .if-purchased {
      font-size: 0.8rem;
      text-align: center;
      .register-here {
        cursor: pointer;
        font-weight: bold;
      }
    }

    .bundle-package {
      font-size: 1.5rem;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .bundle-package-arrow {
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 150ms all ease-in;
      }
      .bundle-package-arrow:hover {
        scale: 1.2;
      }
    }
    .secret-button {
      display: flex;
      justify-content: center;
      margin-bottom: 1em;
    }
  }
}
