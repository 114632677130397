.charts-container {
  overflow: hidden;
  display: flex;
  background-color: #3d3c76;
  max-height: 100vh;
  height: 100vh;

  // max-height: 90vh;
  .charts-container-left {
    position: sticky;
    left: 0;
    top: 0;
    width: 3em;
    z-index: 10;
    @media (max-width: 810px) {
      //phone
      display: none;
    }
  }
  .charts-container-right {
    height: 95vh;
    margin: 0 auto;
    z-index: 0;
    .charts {
      margin: 0 auto;
      border-radius: 0.6em;
      height: 95vh;
      width: 95vw;
    }
  }
}
