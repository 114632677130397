@use "../../../styles/" as *;

.chat_display {
  width: 100%;
  padding: 1em;

  h1 {
    font-size: 1rem;
  }
  &.sent {
    text-align: right;
    h4 {
      font-size: 0.8rem;
      color: #66ffda;
    }
  }

  &.received {
    h4 {
      font-size: 0.8rem;
      color: #66ffda;
    }
  }
  .chat_bubble {
    margin-top: 1em;
    font-size: 0.8rem;
    padding: 0.5em 1em;
    background-color: #d3eaff;
    color: #18174a;
    border-radius: 1em;
    width: auto;
    display: inline-block;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
  }
}
