@keyframes sweepAnim {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.ai-capsule-container {
  animation-name: sweepAnim;
  animation-duration: 1s;
  width: 90%;
  padding: 0.5em;
  background-color: #d4d9ff;
  border-radius: 0.6em;
  color: #18174a;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1em;

  .ai-data {
  }
  .ai-time {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    opacity: 0.7;
  }
}
