.app-main-nav {
  width: auto;
  max-width: 100vw;
  border-radius: 0.6em;
  margin: 1em;
  height: 2.5em;
  background-color: #18174a;
  font-weight: bold;
  display: flex;

  align-items: center;
  padding: 0.5em 1em;
  font-size: 1.2rem;
  gap: 1em;

  .app-nav-hamburger {
    display: none;
    @media (max-width: 1200px) {
      //phone
      flex: 1;
      display: initial;
      cursor: pointer;
    }
  }
  .hamburger-active {
    position: absolute;
    font-weight: normal;
    opacity: 1;
    top: 10%;
    transition: 250ms all ease-in-out;
    transform: translateX(0px);
    transform: scale(1.2);
    z-index: 10;
    height: 100px;
    width: auto;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .hamburger-inactive {
    font-weight: normal;
    position: absolute;
    z-index: 10;
    opacity: 0;
    top: 10%;
    transition: 250ms all ease-in-out;
    transform: translateX(-400px);
    transform: scale(0);
    transform: rotateY(-100deg);

    height: 400px;
    width: auto;
    visibility: hidden;
  }
  .app-main-nav-logotext {
    flex: 3;
    white-space: nowrap;
    @media (max-width: 810px) {
      //phone
      display: flex;
      justify-content: center;
      flex: 1;
    }
  }
  .app-main-nav-watchlist {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 1.4em;
    color: #66ffda;
    cursor: pointer;
    white-space: nowrap;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    @media (max-width: 810px) {
      //phone
      display: none;
    }
  }
  .watchlist-nav-container {
    position: absolute;
    top: 8%;
    right: 15%;
  }
  .watchlist-popup-false {
    z-index: 10;
    position: absolute;
    right: 15%;
    top: -100px;
    transition: 250ms all ease-in-out;
    transform: scale(0);
  }
  .watchlist-popup-true {
    z-index: 10;
    position: absolute;
    right: 15%;
    top: 8%;
    transition: 250ms all ease-in-out;
  }
  .app-main-nav-search {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    background-color: #3d3c76;
    border-radius: 0.6em;
    padding: 0.2em;

    .app-main-nav-search-field {
      border: none;
      outline: none;
      background-color: transparent;
      width: 100%;
      color: #66ffda;
      padding-left: 0.5em;
    }
    .app-main-nav-search-button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
