.profile-main-container {
  display: flex;
  @media (max-width: 1200px) {
    //phone
    height: 100vh;
  }

  .profile-sidebar-container {
    @media (max-width: 1200px) {
      //phone
      display: none;
    }
  }
  .profile-main-container-right {
    background-color: #3d3c76;
    width: 100%;

    .profile-content {
      background-color: #3d3c76;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 90%;
      min-height: 930px;
      min-height: 830px;
      .profile-sub-containers {
        display: flex;
        width: 90%;
        gap: 2em;
        @media (max-width: 745px) {
          //phone
          justify-content: center;
        }
        .profile-left-container {
          display: flex;
          flex-direction: column;
          flex: 1;
          max-width: 500px;
          justify-content: center;
          @media (max-width: 745px) {
            //phone
            width: 100%;
          }
          .profile-container {
            @media (max-width: 745px) {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .profile-textHeading {
              margin: 0 auto;
            }
            .profile-icon {
              margin: 0 auto;
            }
            .profile {
              background-color: #18174a;
              // max-height: 750px;
              min-height: 350px;
              // height: 100vh;
              // max-width: 400px;
              // min-width: 150px;
              flex: 1;
              border-radius: 0.6em;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              padding: 1em;
              gap: 2em;
              .profile-textHeading {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                color: #66ffda;
                padding: 1em;
              }
              .profile-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 10rem;
              }
              .profile-userinfo {
                display: flex;
                flex-direction: column;
                gap: 2em;
                margin-bottom: 2em;
              }
              .profile-bottom {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: space-between;

                height: 200px;
                max-height: 380px;
                min-height: 320px;
                padding-bottom: 1em;
                @media (max-width: 415px) {
                  //phone
                  height: 300px;
                  min-height: 100px;
                }
              }
              // @media (max-width: 700px) {
              //   width: 40vh;
              // }
              // @media (max-width: 415px) {
              //   width: 95%;
              // }
              @media (max-width: 745px) {
                //phone
                width: 95%;
              }
            }
          }
        }

        .profile-right-container {
          background-color: #18174a;
          display: flex;
          flex-direction: column;
          flex: 2;
          gap: 1em;

          padding: 1em;
          border-radius: 0.6em;
          @media (max-width: 630px) {
            //phone
            display: none;
          }
          .profile-dashboard {
            border-radius: 0.6em;
            padding: 1em;
            flex: 1;

            background-color: #3d3c76;
            .dashboard-title {
              display: flex;
              justify-content: center;
              height: 2em;
            }
            .billing-cycle-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 70%;
              .billing-cycle {
                font-weight: bold;
                font-size: 4rem;
                @media (max-width: 1200px) {
                  //phone
                  font-size: 2rem;
                }

                @media (max-width: 812px) {
                  //phone
                  font-size: 1.5rem;
                }
              }
            }
          }
          .profile-active-plan {
            background-color: #3d3c76;
            border-radius: 0.6em;
            padding: 1em;
            width: auto;
            max-width: 50%;
            min-width: 200px;
            flex: 1;
            .activeplan-title {
              height: 2em;
              display: flex;
              justify-content: center;
            }
            .activeplan-container {
              display: flex;
              align-items: center;
              flex-direction: column;
              gap: 1.5em;
              .plan-type {
                display: flex;
                justify-content: center;
                font-weight: bold;
                font-size: 2rem;
                text-align: center;
                @media (max-width: 700px) {
                  //phone
                  font-size: 1rem;
                }
              }
              .price-circle {
                height: 160px;
                width: 160px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-weight: bold;
                font-size: 4rem;
                background-color: #d3eaff;
                color: #18174a;
                @media (max-width: 800px) {
                  //phone
                  height: 120px;
                  width: 120px;
                  font-size: 3rem;
                }
                @media (max-width: 500px) {
                  //phone
                  height: 100px;
                  width: 100px;
                  font-size: 3rem;
                }
                .duration {
                  font-size: 1rem;
                }
              }
            }
          }
          .profile-pricing-card {
            background-color: #3d3c76;
            border-radius: 0.6em;
            padding: 1em;
            display: flex;
            justify-content: space-evenly;
            flex: 1;
            gap: 2em;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}
