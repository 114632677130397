@use "./styles";
@import "normalize.css";
* {
  scrollbar-width: thin;
  scrollbar-color: #b7eeff #9898ff;
}

/* Works on Chrome, Edge, and Safari */

body {
  line-height: 1.2;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #d3eaff;
  background-color: #111042;
  user-select: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #18174a;
}

::-webkit-scrollbar-thumb {
  background-color: #9898ff;
  border-radius: 20px;
  /* border: 2px solid #1a1a1a; */
}
