@use "../../../../styles/" as *;
@keyframes sweepAnim {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.ED-tile-container {
  animation-name: sweepAnim;
  animation-duration: 1s;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #090833;
  //   border-radius: 0.6em;
  height: 3em;
  font-weight: bold;
  font-size: 0.7rem;

  @media (max-width: 1385px) {
    //phone
    font-size: 0.5rem;
  }
  @media (max-width: 810px) {
    //phone
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .flex1 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .green-ticker {
    display: flex;
    justify-content: center;
    background-color: #77ffbe;
    color: #345646;
    padding: 0.2em 0;
    border-radius: 0.6em;
    width: 4em;
    flex-shrink: 1;
  }
  .blue-ticker {
    display: flex;
    justify-content: center;
    background-color: #b7eeff;
    color: rgb(9, 36, 75);
    padding: 0.2em 0;
    border-radius: 0.6em;
    width: 4em;
    flex-shrink: 1;
  }
  .blue-ticker-border {
    display: flex;
    justify-content: center;
    border-radius: 5px;
    border: 2px solid #b7eeff;
    color: rgb(255, 255, 255);
    padding: 0.2em 0;

    width: 4em;
    flex-shrink: 1;
  }
  .red-ticker {
    display: flex;
    justify-content: center;
    background-color: $base-red;
    color: #590000;
    padding: 0.2em 0;
    border-radius: 0.6em;
    width: 4em;
    flex-shrink: 1;
  }
  .green-item {
    color: $base-green;
  }
  .red-item {
    color: $base-red;
  }
  .blue-item {
    color: $base-blue;
  }
}
