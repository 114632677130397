.watchlist-container {
  width: 20em;
  min-height: 300px;
  max-height: 500px;
  height: auto;
  // height: 15em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #18174a;
  border-radius: 0.6em 0.6em;
  box-shadow: 0px 2px 5px 6px rgba(0, 0, 0, 0.2);

  .watchlist-search-container {
    width: 80%;
    margin: 0 auto;

    .watchlist-search {
      margin: 1em;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      background-color: #3d3c76;
      border-radius: 0.6em;
      padding: 0.2em;

      .watchlist-search-field {
        border: none;
        outline: none;
        background-color: transparent;
        width: 100%;
        color: #66ffda;
      }
      .watchlist-search-button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .watchlist-tiles {
    // height: 10em;
    margin-bottom: 2em;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #090833 #090833;
    scroll-behavior: smooth;
    transition: scrollbar-color 100ms ease-in;
    &:hover {
      overflow-y: scroll;
      scrollbar-color: #4d4d7e #090833;
    }
  }

  .watchlist-arrow {
    position: absolute;
    bottom: 0;
    width: 100%;

    font-size: 2rem;
    display: flex;
    height: 1em;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
