.options-order-flow-container {
  height: 100%;
  // display: none;
  @media (max-width: 1200px) {
  }

  .oof-header {
    background-color: #18174a;

    border-radius: 0.6em 0.6em 0 0;
    margin: 0 auto;
    min-width: 740px;
    // height: 500px;
    min-height: 400px;
    height: 72vh;
    width: 97.5%;

    @media (max-width: 1200px) {
      min-width: auto;
      height: 92vh;
    }
    // @media (min-height: 1080px) {
    //   min-width: auto;
    //   height: 80.5vh;
    //   border-radius: 0.6em;
    // }
    // @media (min-height: 2500px) {
    //   min-width: auto;
    //   height: 93vh;
    //   border-radius: 0.6em;
    // }

    .oof-textheading {
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      height: 4em;
      color: #66ffda;
    }
    .mobile-home-links {
      display: flex;
      justify-content: space-between;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        min-height: 40px;
        flex: 1;
        font-weight: 400;
        font-size: 0.5rem;
        border-radius: 0.6em 0.6em 0 0;
        cursor: pointer;
        text-align: center;
      }
      .linkActive {
        color: #66ffda;
        background-color: #090833;
      }
      .linkInactive {
        color: #66ffd959;
      }
    }
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #18174a;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #9898ff;
      border-radius: 20px;
      /* border: 2px solid #1a1a1a; */
    }
    .oof-table {
      font-size: 0.7rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-weight: bold;
      height: 2em;
      background-color: #18174a;
      border-radius: 0.6em 0.6em 0 0;

      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: #18174a #18174a;
      scroll-behavior: smooth;
      transition: scrollbar-color 100ms ease-in;

      &:hover {
        overflow-y: scroll;
      }
      @media (max-width: 1200px) {
        //phone
        display: none;
      }
      .cyanTxt {
        color: #66ffda;
      }
      .greenTxt {
        color: #a9ff66;
      }
      .redTxt {
        color: #ff6275;
      }
      .flex1 {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .oof-main-tiles {
      // border: 5px solid #090833;
      // background-color: #090833;
      // max-height: 650px;
      height: 85%;

      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: #090833 #090833;
      scroll-behavior: smooth;
      transition: scrollbar-color 100ms ease-in;

      &:hover {
        overflow-y: scroll;
        scrollbar-color: #4d4d7e #090833;
      }
      &.loading_container {
        display: flex;
        width: 100%;
        height: 400px; // change for responsiveness
        justify-content: center;
        align-items: center;
      }
      .app-main-cards-container-mobile {
        background-color: #090833;

        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1em 0 1em 0;
        // margin-top: 1em;
        // margin-bottom: 1em;

        .app-card-container {
          background-color: #3d3c76;
          height: 60%;
          display: flex;

          margin: 0em 1em 0em 1em;
          border-radius: 0.6em;
          padding: 1em;
          font-weight: bold;
          // justify-content: space-between;
          // align-items: space-evenly;
          .app-card-heading {
            font-size: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .app-card-subheading {
            display: flex;
            align-items: flex-end;
            height: 100%;
            font-size: 1.5rem;
          }

          .RCP {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;

            user-select: none;
            .progress-value {
              position: absolute;
              top: 45%;
            }
          }
          .green {
            color: #82c571;
          }
        }
      }
      .oof-tile-background {
        background-color: #090833;
      }
    }
  }
}
