.heroSection-Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 800px;
  @media (max-width: 810px) {
    //phone
    height: 700px;
  }
  .heroSection {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    gap: 8em;
    @media (max-width: 810px) {
      //phone
      justify-content: center;
      gap: 3em;
    }
    .heroSection-text-and-buttons {
      width: 50%;

      @media (max-width: 810px) {
        //phone
        width: 100%;
        min-width: 200px;
      }
    }

    @media (max-width: 810px) {
      //phone
      flex-direction: column-reverse;
    }

    .heroSection-text-heading {
      font-size: 3.5rem;
      font-weight: bold;
      line-height: 1.2;
      margin-bottom: 0.5em;
      @media (max-width: 810px) {
        //phone
        font-size: 2rem;
        text-align: center;
      }
    }
    .heroSection-text-subHeading {
      font-size: 1.2rem;
      @media (max-width: 810px) {
        //phone
        font-size: 0.8rem;
        text-align: center;
      }
    }
    .heroSection-heroImage-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .heroSection-buttons {
      margin-top: 4vh;
      display: flex;
      justify-content: flex-start;
      gap: 2em;
      @media (max-width: 810px) {
        //phone
        justify-content: center;
      }
    }
  }
  .scroll-down {
    // position: absolute;
    // bottom: 10%;
    margin-top: 5em;
    transform: scale(0.6);

    @media (max-width: 810px) {
      //phone
      display: none;
    }
  }
}
