.ED-container {
  width: 100%;
  height: 100%;
  background-color: #18174a;
  border-radius: 0.6em;

  // @media (max-width: 1385px) {
  //   //phone
  //   width: 80%;
  //   height: 100%;
  //   font-size: 0.5rem;
  // }
  .ED-header {
    height: 40vh;
    // min-height: 300px;

    .ED-textheading {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 2.5em;
      font-weight: bold;
      color: #66ffda;
      text-align: center;
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #18174a;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #9898ff;
      border-radius: 20px;
      /* border: 2px solid #1a1a1a; */
    }
    .ED-table {
      font-size: 0.7rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 4em;
      font-weight: bold;
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: #18174a #18174a;
      @media (max-width: 1385px) {
        //phone
        font-size: 0.5rem;
      }
      .cyanTxt {
        color: #66ffda;
      }
      .greenTxt {
        color: #a9ff66;
      }
      .redTxt {
        color: #ff6275;
      }
      .flex1 {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ED-tiles {
      overflow-y: scroll;
      // max-height: 31.25vh;
      max-height: 75%;
      // height: 28vh;
      // min-height: 200px;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: #090833 #090833;
      scroll-behavior: smooth;
      transition: scrollbar-color 100ms ease-in;

      &:hover {
        overflow-y: scroll;
        scrollbar-color: #4d4d7e #090833;
      }
    }
  }
  .loading_container {
    display: flex;
    width: 100%;

    height: 400px; // change for responsiveness
    justify-content: center;
    align-items: center;
  }
}

.ED-mobile-container {
  .ED-table {
    font-size: 0.7rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 4em;
    font-weight: bold;
    background-color: #090833;
    // overflow-y: scroll;
    // overflow-x: hidden;
    // scrollbar-width: thin;
    // scrollbar-color: #18174a #18174a;
    .flex1 {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 1385px) {
      //phone
      font-size: 0.5rem;
    }
    @media (min-width: 1385px) {
      //phone
      display: none;
    }
  }
}
