.login-phone-container {
  max-height: 600px;
  min-height: 600px;
  max-width: 370px;
  min-width: 300px;
  width: 60vw;
  opacity: 0;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #3d3c76;
  padding: 2em;
  border-radius: 0.6em;

  .login-header {
    margin-bottom: 0.4em;
    font-weight: bold;
    font-size: 2rem;
  }
  .forgot-password {
    margin: 1em 0;
    font-size: 0.8rem;
    text-align: right;
  }
  .register-here {
    font-weight: bold;
  }
  .bundle-package {
    width: 80%;
    margin: 0 auto;
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    .bundle-package-arrow {
      font-size: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 150ms all ease-in;
    }
    .bundle-package-arrow:hover {
      scale: 1.2;
    }
  }
}
