.benefits-container {
  padding: 2em;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  @media (max-width: 415px) {
    // grid-template-rows: repeat(4, 1fr);
  }
  .subscription-cards {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 2em;
    margin: 0 auto 5em;
    @media (max-width: 415px) {
      //phone
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 2em;
    }
  }
  .benefits-hover-container {
    transition: 250ms all ease-in-out;
    height: 100vh;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .benefits-heading {
      transition: 250ms all ease-in-out;

      font-weight: bold;
      font-size: 1.5rem;
      color: #b7eeff;
      padding: 0.3em;
      @media (max-width: 415px) {
        //phone
        font-size: 0.8rem;
      }
    }
    .benefits-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2em;
      align-items: center;

      height: 16em;
      @media (max-width: 415px) {
      }
      .benefits-content {
        border-radius: 0.6em;
        display: grid;
        min-width: 200px;
        width: 80vw;
        max-width: 930px;
        background-color: #d3eaff;
        grid-template-columns: repeat(4, 1fr);
        justify-content: center;
        align-content: center;

        @media (max-width: 810px) {
          // width: 16em;

          grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 415px) {
          // width: 16em;

          grid-template-columns: repeat(2, 1fr);
        }
      }

      .benefits-item {
        padding: 0.7em;
        display: flex;
        margin: 0.5em;
        font-weight: bold;
        text-align: left;
        border-radius: 0.6em;
        color: #111042;

        @media (max-width: 40em) {
          //phone
          text-align: left;
          font-size: 0.8rem;
        }
      }
    }
  }
}
