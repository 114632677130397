@use "../../../styles" as *;
@keyframes scaleUp {
  0% {
    transform: scale(0);
  }
  65% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes rotate {
  0% {
    transform: rotateY(180deg);

    opacity: 0;
  }

  100% {
    transform: rotateY(360deg);

    opacity: 1;
  }
}
.register {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  // animation-name: scaleUp;

  // transition: 250ms all ease-in-out;

  &.first {
    // transform: rotateY(180deg);
    animation-name: scaleUp;
    animation-duration: 0.5s;
    transition: 250ms all ease-in-out;
  }
  &.notfirst {
    animation-name: rotate;
    animation-duration: 0.5s;
    transform: rotateY(360deg);

    // transition: 250ms all ease-in-out;
  }
  .input_container {
    border-radius: 0.6em;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;

    background: #3d3c76;
    color: #edfbff;
    border-radius: 15px;

    width: 100%;
    max-width: 800px;
    min-width: 640px;

    @media (max-width: 770px) {
      margin: 2em 0;
      min-width: 320px;
      height: auto;
    }
    @media (max-width: 410px) {
      margin: 0;
    }
    h1 {
      margin: 0;
      font-size: 2.1rem;
      font-weight: bold;
      // letter-spacing: 0.1em;
    }
    .register_form {
      // min-height: 440px;

      // max-width: 600px;
      // min-width: 370px;
      // width: 90vw;

      margin-top: 2em;
      display: flex;
      flex-direction: column;

      @media (max-width: 770px) {
        align-items: center;
      }
      input {
        &:active,
        &:focus {
          // box-shadow: 0 0 0 2pt #9ed0ff;
          outline: none;
        }
      }
      button {
        border-radius: 0.6em;
        margin-top: 3em;
        width: 85%;
        align-self: center;
        background: #dfabff;
      }
      .club {
        display: flex;
        justify-content: space-between;
        min-width: 600px;
        gap: 1em;
        @media (max-width: 770px) {
          flex-direction: column;
          align-items: center;
          min-width: 300px;
          gap: 0;
          // align-items: center;
        }
      }
      .form_group {
        position: relative;
        // margin: 3.5em 0.5em 0;
        .form_check_input {
          margin: 0 0.5em 0 0.8em;
        }
        .form_check_label {
          font-size: 0.9rem;
          font-weight: 400;
          a {
            width: fit-content;
            font-size: 0.7rem;
            font-weight: 400;
            opacity: 0.7;
            color: white;
            // display: block;
            margin-left: 5px;
            position: relative;
            text-decoration: none;
          }
        }
        .input_feedback {
          position: absolute;
          bottom: -15px;
          left: 34px;
          font-size: 0.6rem;
          // color: red;
          letter-spacing: 0.05em;
        }
      }
    }
    .signin_links {
      width: fit-content;
      color: #fff;
      font-size: 0.75em;
      margin-top: 1.4em;
      letter-spacing: 0.03em;
      font-weight: 300;
      list-style: none;
      span {
        color: #fff;
        font-weight: 400;
      }
    }
  }
}
