@use "../../styles" as *;

.input {
  position: relative;

  .container {
    display: flex;
    background: khaki;
    color: rgb(233, 241, 255);
    margin-bottom: 1.5em;
    padding: 1em;
    width: 18em;

    border-radius: 0.6em !important;
    &.blank {
      background: #141414;
      margin-bottom: 0;
      box-shadow: none;
      width: 12em;
      @media (max-width: 900px) {
        width: 9em;
      }
      input {
        background: #141414;
      }
    }
    &.checkout,
    &.form {
      background: #d4d9ff;
      width: 100%;
      height: 50px;
      box-shadow: none;
      margin-bottom: 0;
      border: 1px solid #d2d2d7;
      border-radius: 18px;
      align-items: center;
      .logo {
        color: #3d3c76;
      }
      &.form {
        margin-bottom: 1.5em;
      }
      input {
        width: 100%;

        font-size: 16px;
        background: #d4d9ff;
        padding-top: 2px;
        font-family: Montserrat, sans-serif;
        &::placeholder {
          font-weight: 300;
        }
      }
    }
    .logo {
      font-size: 1.4rem;
    }
    .search {
      text-transform: uppercase;
    }
    input {
      border: none;
      font-family: "Lato", sans-serif;
      padding-left: 1em;
      letter-spacing: 0.05em;
      // font-size: 1.2rem;
      background: $base-brown;
      color: #3d3c76;
      width: 100%;
      outline: none;
    }
    input:-webkit-autofill {
      background-color: transparent !important;
      box-shadow: none;
    }
  }
  .input_feedback {
    position: absolute;
    bottom: -2em;
    left: 3em;
    font-size: 0.7rem;
    color: #ff6275;
    letter-spacing: 0.05em;
  }
}
