$base-blue: #67b1ff;
$base-color: #1a1a1a;
$base-back: #3f3f3f;
$base-yellow: #da7b0c;
$base-purple: #bc13fe;
$dark-purple: #804299;
$base-green: #77ffbe;
$base-red: #ff6275;
$base-brown: #463443;
$base-golden: #ffd700;
