.box {
  background-color: #5c5aca;
  padding: 2em;
  min-width: 160px;
  max-width: 280px;
  min-height: 160px;
  max-height: 360px;
  height: 80vh;
  width: 80vw;
  border-radius: 0.6em;
  font-weight: 500;

  @media (max-width: 415px) {
    //phone
    width: 18em;
    height: 18em;
    padding: 1em;
    text-align: center;
    font-size: 0.6rem;
    margin-bottom: 0;
  }
  @media (max-width: 810px) {
    //tab
    width: 18em;
    height: 18em;
    padding: 1em;
    text-align: center;
    font-size: 0.6rem;
    margin-bottom: 0;
  }
  .card-icon-container {
    display: flex;
    justify-content: center;
    height: 3em;
    @media (max-width: 70em) {
      //phone
      height: 4em;
    }
    .card-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      // scale: 1.6;
      // margin-bottom: 1.15em;
      background-color: #b7abff;
      border-radius: 100%;
      width: 2em;
      height: 2em;
      // padding: 0.1em;
      font-size: 2rem;
      color: #111042;
      @media (max-width: 810px) {
        //phone
        font-size: 1.2rem;
      }
    }
  }

  .card-heading {
    font-weight: bold;
    font-size: 1.5rem;
    color: #b7eeff;
    margin-bottom: 0.5em;
    @media (max-width: 810px) {
      //phone
      font-size: 0.8rem;
    }
  }
}
