@use "../../../../styles" as *;

.formik-login-container {
  margin-top: 1em;
  transition: 250ms ease-out;
  // display: flex;
  // flex-direction: column;
  // background-color: blue;
}
.signin-phone {
  font-family: "Poppins", sans-serif;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .phone-form {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .title {
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
      font-size: 2rem;
      margin: 0;
    }
    .intl-tel-input {
      color: #3d3c76;
      // width: 100%;
      margin-bottom: 0.5em;
      text-shadow: none;

      .flag-container {
        .selected-flag {
          border-top-left-radius: 1em;
          border-bottom-left-radius: 1em;
        }
        .country-list {
          max-width: 100%;
          border-radius: 7px;
        }
      }
      input {
        width: 100%;
        max-width: 100%;
        height: 50px;
        background: #d4d9ff;
        color: #3742a0;

        letter-spacing: 0.05em;
        padding: 1em;
        padding-left: 53px;
        outline: none;
        border-radius: 0.6em;
        border: 1px solid #d2d2d7;
        font-size: 1.1rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
      }
    }

    .dummy {
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
      font-size: 0.7rem;
      height: 20px;
      text-align: center;
      color: rgb(255, 255, 255);
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Poppins", sans-serif;
      font-weight: bold;
      color: #3d3c76;
      // width: 60%;
      height: 50px;
      margin-top: 0.5em;
      background: #dfabff;
      // &:disabled {
      //   opacity: 0.5;
      //   outline: none;
      //   border: none;
      //   box-shadow: none;
      //   cursor: default;
      // }
    }
    .error {
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
      color: #ff6275;
      margin-top: 2px;
      font-size: 14px;
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
}
