@use "../../styles" as *;

.custom_button {
  height: 3.2em;
  margin-top: 0.8em;
  padding: 1rem;
  display: block;
  background: #dfabff;
  color: #3d3c76;
  border-radius: 0.6em;
  border: none;
  font-size: 1.1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  box-sizing: border-box;
  transition: transform 100ms ease-in-out;
  width: 60%;
  cursor: pointer;

  &.homebutton {
    background: #dfabff;
    border-radius: 0.6em;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 0.9rem;
    letter-spacing: 0em;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4em;
    width: 14em;
  }
  &.redbtn {
    background: $base-red;
    color: white;
    border-radius: 0.6em;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 0.9rem;
    letter-spacing: 0em;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4em;
    width: 14em;
  }
  &.whitebtn {
    background: white;
    color: #1f1e57;
    border-radius: 0.6em;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 0.9rem;
    letter-spacing: 0em;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4em;
    width: 14em;
  }
  &.bluebtn {
    background: rgb(0, 2, 67);
    // background-image: linear-gradient(indigo, rgb(0, 2, 67));

    color: #ffffff;
    border-radius: 0.6em;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 0.9rem;
    letter-spacing: 0em;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4em;
    width: 14em;
    transition: 250ms all ease-in-out;
    &:hover {
      scale: 1.05;
      box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.6);
    }
  }
  &.goldbtn {
    color: #ffffff;
    background-color: rgb(255, 196, 0);
    border-radius: 0.6em;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 0.9rem;
    letter-spacing: 0em;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4em;
    width: 14em;
  }
  &.outlinebutton {
    background: none;
    border: 1px solid $base-purple;
    border-radius: 0.2em;
    text-transform: capitalize;
    font-family: "inter", sans-serif;
    font-weight: 700;
    font-size: 0.9rem;
    letter-spacing: 0em;
    &:hover {
      background: $base-purple;
    }
  }

  &:hover {
    transform: translateY(-5px);
  }

  &:active,
  &:focus {
    outline: none;
  }
  &:active {
    transform: scale(0.95);
  }
  &:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
  }

  &.prem {
    background: $base-color;
    // border: 2px solid #2997ff;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.6);
    color: $base-blue;
  }
}
