.history-container {
  display: flex;
  height: 100%;
  background-color: #3d3c76;
  min-height: 82vh;

  .history-right-container {
    width: 100%;
    height: 100%;
  }
  .history-form-container {
    display: flex;
    justify-content: center;
    padding-bottom: 1.2em;
    .history-form {
      display: flex;
      width: 90%;
      background-color: #18174a;
      border-radius: 0.6em;
      height: 4em;
      display: flex;
      justify-content: center;
      gap: 2em;
      align-items: center;
      @media (max-width: 415px) {
        //phone
      }
      .history-search,
      .history-date-start,
      .history-date-end {
        font-family: "poppins";
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        height: 2em;
        width: 10em;
        background-color: #3d3c76;
        outline: none;

        border-radius: 0.6em;
        @media (max-width: 640px) {
          //phone
          font-size: 0.4rem;
        }
      }
    }
  }
}
