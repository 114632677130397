.AI-container {
  height: 100%;
  background-color: #18174a;
  border-radius: 0.6em;

  .AI-header {
    height: 40vh;
    // min-height: 300px;
    .AI-textheading {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 2.5em;
      font-weight: bold;
      color: #66ffda;
      text-align: center;
      padding-bottom: 0.2rem;
    }
    .AI-capsules-container {
      display: flex;
      flex-direction: column;
      gap: 1em;
      margin-top: 1em;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: #18174a #18174a;
      scroll-behavior: smooth;
      transition: scrollbar-color 100ms ease-in;

      &:hover {
        overflow-y: scroll;
        scrollbar-color: #9898ff #18174a;
      }
    }
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #18174a;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #9898ff;
      border-radius: 20px;
      /* border: 2px solid #1a1a1a; */
    }
    .AI-table {
      font-size: 0.7rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 4em;
      font-weight: bold;
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: #18174a #18174a;

      .cyanTxt {
        color: #66ffda;
      }
      .greenTxt {
        color: #a9ff66;
      }
      .redTxt {
        color: #ff6275;
      }
      .flex1 {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .AI-tiles {
      overflow-y: scroll;
      // max-height: 31.25vh;
      // height: 28vh;
      max-height: 75%;
      // min-height: 200px;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: #090833 #090833;
      scroll-behavior: smooth;
      transition: scrollbar-color 100ms ease-in;
      &:hover {
        overflow-y: scroll;
        scrollbar-color: #4d4d7e #090833;
        ::-webkit-scrollbar-thumb {
          background-color: #9898ff;
          border-radius: 20px;
          /* border: 2px solid #1a1a1a; */
        }
      }
    }
  }
  .loading_container {
    display: flex;
    width: 100%;
    height: 41em; // change for responsiveness
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 810px) {
    //phone
    display: none;
  }
}
