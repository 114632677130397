.packages-heading {
  @media (max-width: 415px) {
    //phone
    font-size: 1rem;
  }
  .packages-card {
    margin-top: 1em;
    height: 50em;
    display: flex;
    gap: 2em;
    flex-direction: column;
    @media (max-width: 70em) {
      //phone

      gap: 4em;
    }
    @media (max-width: 40em) {
      //phone
      flex-direction: column;
      gap: 4em;
    }
    .packages-benefits {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .subscription-Cards {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 4em;
    }
  }
}
