.display-login-register {
  position: absolute;
  display: flex;
  top: 80%;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.display-login-register-bg {
  position: absolute;
  top: 0;
  background-color: #000000ce;
  height: 390vh;
  width: 100vw;
  z-index: -10;
}
.active {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  scale: 1;
  z-index: 10;
  // filter: blur(20px);
  height: 100vh;
  width: 100vw;
}
.inactive {
  scale: 0;
}
