.hamburger-container {
  background-color: #d3eaff;
  color: #3d3c76;

  padding: 2em;

  border-radius: 0.6em;
  left: 1em;
  box-shadow: 7px 6px 10px 3px rgba(0, 0, 0, 0.3);

  .hamburger-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    cursor: pointer;
    .hamburger-links-home,
    .hamburger-links-history,
    .hamburger-links-charts,
    .hamburger-links-profile,
    .hamburger-links-news,
    .hamburger-links-logout {
      // box-shadow: inset 0 0 #18174a;
      // border-radius: 0.2em;
      // padding: 0.5em;
      // transition: 150ms ease-in-out;
      // &:active {
      //   box-shadow: inset 5em 0em #d3eaff;
      // }
    }
  }
}
