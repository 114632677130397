.app-card-container {
  background-color: #18174a;
  height: 8em;
  display: flex;
  flex: 1;
  margin: 0 0 1em 1em;
  border-radius: 0.6em;
  padding: 1em;
  font-weight: bold;
  justify-content: space-between;
  .app-card-heading {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .app-card-subheading {
    display: flex;
    align-items: flex-end;
    height: 100%;
    font-size: 1.5rem;
  }

  .RCP {
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    user-select: none;
    .progress-value {
      position: absolute;
      top: 45%;
    }
  }
  .green {
    color: #82c571;
  }
}
