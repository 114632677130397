.nav-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
  .nav-bar {
    display: flex;
    width: 80%;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    margin-top: 1em;
    @media (max-width: 429px) {
      //phone
      justify-content: center;
    }
    .nav-logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 230px;
      cursor: pointer;
      gap: 10px;

      .nav-logo-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .nav-logo-icon {
          width: 3rem;
        }
      }
      .nav-logo-text {
        // @media (max-width: 70em) {
        //   //phone
        //   display: none;
        // }
      }
    }
    .gift-mobile {
      position: relative;
      .gift-anim {
        border-radius: 100%;
        width: 80px;
        height: 80px;
        transform: translateY(-10px);
        cursor: pointer;
        @media (min-width: 500px) {
          //phone
          display: none;
        }
      }
    }
    .nav-links {
      display: flex;
      list-style: none;
      align-items: center;

      gap: 1em;
      @media (max-width: 500px) {
        //phone
        display: none;
      }

      .gift {
        position: relative;
        .gift-anim {
          border-radius: 100%;
          width: 100px;
          height: 100px;
          transform: translateY(-20px);
          cursor: pointer;
        }
        &:hover .gift-anim-text {
          background-color: #b7abff;
          color: #111042;
          padding: 1em;
          border-radius: 0.6em;
          position: absolute;
          transition: 300ms all ease-out;
          // transform: scale(1);
          box-shadow: inset 10em 0em #b7abff;
          transform: translateX(10px);
          left: -230px;
          top: 28px;
          z-index: 100;
          opacity: 1;
        }

        .gift-anim-text {
          color: #111042;
          padding: 1em;
          border-radius: 0.6em;
          position: absolute;
          font-size: 0.8rem;
          opacity: 0;
        }
      }
    }
    .nav-links-login,
    .nav-links-register {
      transition: 50ms all ease-in-out;
      cursor: pointer;
      &:hover {
        border-bottom: 4px solid #d3eaff;
      }
    }
  }
  .nav-arrow-menu {
    font-size: 1.5rem;
    display: none;
    cursor: pointer;
    transition: 150ms all ease-in-out;

    @media (max-width: 500px) {
      display: flex;
      justify-content: center;
      align-items: center;

      .arrow-right {
        transition: 250ms ease-in-out;
        display: flex;
        gap: 4px;
        flex-direction: column;
        .nav-burger-1 {
        }
        .nav-burger-2 {
        }
      }
      .arrow-left {
        display: flex;
        gap: 4px;
        flex-direction: column;
        transition: 250ms ease-in-out;
      }
    }
  }
}
