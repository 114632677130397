.news-container {
  overflow: hidden;
  display: flex;
  background-color: #3d3c76;
  // max-height: 100vh;

  // max-height: 90vh;
  .news-container-left {
    @media (max-width: 810px) {
      //phone
      display: none;
    }
  }
  .news-container-right {
    width: 100%;
    padding: 0 2em;
    height: 100vh;
    min-height: 500px;
    .news-content {
      display: flex;
      height: 88%;
      gap: 1em;
      h1 {
        padding: 0.5em;
        font-size: 2rem;
      }
      .news-content-left {
        border-radius: 0.6em;
        background-color: #18174a;
        flex: 1;
        overflow-y: hidden;
        .historical-news-heading {
          display: flex;
          justify-content: center;
        }
        .historical-news-search {
          display: flex;
          justify-content: center;
          gap: 1em;
          margin: 1em;
        }
        .historical-news-data {
          display: flex;
          flex-direction: column;
          gap: 1em;
          margin-top: 1em;
          height: 100%;
          overflow-y: scroll;
          overflow-x: hidden;
          scrollbar-width: thin;
          scrollbar-color: #18174a #18174a;
          scroll-behavior: smooth;
          transition: scrollbar-color 100ms ease-in;

          &:hover {
            overflow-y: scroll;
            scrollbar-color: #9898ff #18174a;
          }
          &.loading_container {
            display: flex;
            width: 100%;
            height: 250px; // change for responsiveness
            justify-content: center;
            align-items: center;
          }
        }
        .mobile-home-links {
          display: flex;
          justify-content: space-between;
          li {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 40px;
            min-height: 40px;
            flex: 1;
            font-weight: 400;
            font-size: 0.5rem;
            border-radius: 0.6em 0.6em 0 0;
            cursor: pointer;
            text-align: center;
          }
          .linkActive {
            color: #66ffda;
            background-color: #090833;
          }
          .linkInactive {
            color: #66ffd959;
          }
        }
      }
      .news-content-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1em;
        @media (max-width: 810px) {
          //phone
          display: none;
        }
        .live-news {
          // flex: 1;
          background-color: #18174a;
          user-select: none;
          border-radius: 0.6em;
          position: relative;
          overflow-y: hidden;
          .live-news-heading {
            display: flex;
            justify-content: center;
          }
          .live-news-minimize-expand {
            position: absolute;
            right: 1em;
            top: 1em;
            font-size: 1.5rem;
            cursor: pointer;
          }
          &.flex-change-true {
            flex: 0.15;
            transition: 250ms all ease-in-out;
          }
          &.flex-change-false {
            flex: 1;
            transition: 250ms all ease-in-out;
          }
          .live-news-container-active {
            display: flex;
            flex-direction: column;
            gap: 1em;
            margin-top: 1em;
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            scrollbar-width: thin;
            scrollbar-color: #18174a #18174a;
            scroll-behavior: smooth;
            transition: scrollbar-color 100ms ease-in;

            &:hover {
              overflow-y: scroll;
              scrollbar-color: #9898ff #18174a;
            }
            &.loading_container {
              display: flex;
              width: 100%;
              height: 250px; // change for responsiveness
              justify-content: center;
              align-items: center;
            }
          }
        }
        .source-news {
          position: relative;
          border-radius: 0.6em;
          // flex: 1;
          background-color: #18174a;
          overflow-y: hidden;
          .source-news-heading {
            display: flex;
            justify-content: center;
          }
          .source-news-minimize-expand {
            position: absolute;
            right: 1em;
            top: 1em;
            font-size: 1.5rem;
            cursor: pointer;
          }
          &.flex-change-true {
            flex: 0.15;
            transition: 250ms all ease-in-out;
          }
          &.flex-change-false {
            flex: 1;
            transition: 250ms all ease-in-out;
          }
          .source-news-container-active {
            display: flex;
            flex-direction: column;
            gap: 1em;
            margin-top: 1em;
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            scrollbar-width: thin;
            scrollbar-color: #18174a #18174a;
            scroll-behavior: smooth;
            transition: scrollbar-color 100ms ease-in;

            &:hover {
              overflow-y: scroll;
              scrollbar-color: #9898ff #18174a;
            }
            &.loading_container {
              display: flex;
              width: 100%;
              height: 250px; // change for responsiveness
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      //mobile
      .news-content-right-mobile {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1em;
        height: 100%;
        @media (max-width: 810px) {
          //phone
          display: initial;
        }
        .live-news {
          // flex: 1;
          height: 100%;
          background-color: #18174a;
          user-select: none;
          border-radius: 0.6em;
          position: relative;
          .live-news-heading {
            display: flex;
            justify-content: center;
          }
          .live-news-minimize-expand {
            position: absolute;
            right: 1em;
            top: 1em;
            font-size: 1.5rem;
            cursor: pointer;
          }
          &.flex-change-true {
            flex: 0.15;
            transition: 250ms all ease-in-out;
          }
          &.flex-change-false {
            flex: 1;
            transition: 250ms all ease-in-out;
          }
          .live-news-container-active-m {
            display: flex;
            flex-direction: column;
            gap: 1em;
            margin-top: 1em;
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            scrollbar-width: thin;
            scrollbar-color: #18174a #18174a;
            scroll-behavior: smooth;
            transition: scrollbar-color 100ms ease-in;

            &:hover {
              overflow-y: scroll;
              scrollbar-color: #9898ff #18174a;
              &.loading_container {
                display: flex;
                width: 100%;
                height: 250px; // change for responsiveness
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
        .source-news {
          position: relative;
          height: 100%;
          border-radius: 0.6em;
          // flex: 1;
          background-color: #18174a;
          .source-news-heading {
            display: flex;
            justify-content: center;
          }
          .source-news-minimize-expand {
            position: absolute;
            right: 1em;
            top: 1em;
            font-size: 1.5rem;
            cursor: pointer;
          }
          &.flex-change-true {
            flex: 0.15;
            transition: 250ms all ease-in-out;
          }
          &.flex-change-false {
            flex: 1;
            transition: 250ms all ease-in-out;
          }
          .source-news-container-active-m {
            display: flex;
            flex-direction: column;
            gap: 1em;
            margin-top: 1em;
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            scrollbar-width: thin;
            scrollbar-color: #18174a #18174a;
            scroll-behavior: smooth;
            transition: scrollbar-color 100ms ease-in;

            &:hover {
              overflow-y: scroll;
              scrollbar-color: #9898ff #18174a;
            }
          }
        }
      }
    }
  }
}
