// .MainContent-container {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   position: initial;

.testimonials {
  opacity: 1;
  text-align: center;
}

.packages-container {
  width: 80%;

  border-radius: 0.6em;
  padding: 2em;
  margin: 0 auto 0;

  .packages {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 40em) {
      //phone

      margin: 0 auto;
    }
    .packages-heading {
      font-size: 3.5rem;
      font-weight: bold;
      line-height: 1.2;
      color: #b7eeff;
      margin-bottom: 0.5em;
      @media (max-width: 40em) {
        //phone
        font-size: 2rem;

        margin: 0 auto;
      }
    }
    .packages-subHeading {
      font-size: 1.4rem;
      margin-bottom: 1em;
      font-weight: 500;
      @media (max-width: 40em) {
        //phone
        font-size: 0.8rem;

        margin: 0 auto;
      }
    }
  }
}
