@use "../../styles" as *;

.checkout {
  min-height: 100vh;
  width: 100vw;
  display: grid;
  align-items: center;
  justify-items: center;
  margin-bottom: 0 0 1em;
  height: auto;
  background-color: #3d3c76;
  background: #18174a;
  .container {
    width: 90%;
    max-width: 1100px;
    display: flex;
    padding: 1.5em 0;
    margin: 2em 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    border-radius: 1em;
    height: 80%;
    background: #18174a;
    @media (max-width: 850px) {
      width: 80%;
    }
    .title {
      font-size: 2rem;
      color: #d3eaff;
      font-weight: 700;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      // display: none;
      // position: absolute;
      // top: 15%;
    }
    .product_cont {
      display: flex;
      gap: 1em;
      justify-content: center;

      @media (max-width: 850px) {
        flex-direction: column;
      }
      .product_desc {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 2em;
        font-family: "Poppins", sans-serif;

        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        border-radius: 7px;
        color: rgb(255, 255, 255);
        background-color: #3d3c76;

        padding: 40px;
        box-shadow: 0px 2px 10px 6px rgba(0, 0, 0, 0.2);
        .checkout_prod_container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1em;
          .checkout_prod_bottom {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 5em;
            gap: 1em;
            align-items: center;
            text-align: center;
            .offer {
              color: rgba(255, 255, 255, 0.692);
              font-size: 0.8rem;
            }
          }
          .other-plans {
            gap: 5px;
            display: flex;
          }
        }
        @media (max-width: 450px) {
          padding: 10px;
        }
        h3 {
          font-size: 1.7rem;
          font-weight: 400;
          letter-spacing: 0.1em;
          margin: 10px 0 0 0;
        }
        img {
          border-radius: 7px;
          width: 150px;
          margin-bottom: 1em;
        }
        h4 {
          font-size: 1rem;
          font-weight: 300;
        }
        h2 {
          font-size: 5rem;
          font-weight: 400;
          width: 100%;

          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 50%;
          padding: 0.5em;
          color: #18174a;
          background-color: #d4d9ff;
          height: 250px;
          width: 250px;
          font-weight: bold;

          .duration {
            font-size: 0.6rem;
            font-weight: 400;
            margin-right: auto;
            align-self: flex-end;
            margin-bottom: 0.7em;
            color: rgb(255, 255, 255);
          }
          .offer {
            font-size: 1rem;
            color: rgb(255, 255, 255);
            span {
              font-size: 0.6rem;
            }
          }
        }
        .original {
          font-size: 1rem;
          letter-spacing: 0;
          color: rgb(255, 255, 255);
          text-align: center;
        }
      }

      .payment_cont {
        flex: 1;
        // width: 40vw;
        // min-width: 350px;
        // max-width: 500px;
        display: flex;
        flex-direction: column;

        justify-content: space-between;

        border-radius: 7px;
        color: rgb(255, 255, 255);

        background: #3d3c76;
        padding: 40px;
        box-shadow: 0px 2px 10px 6px rgba(0, 0, 0, 0.2);
        @media (max-width: 450px) {
          padding: 10px;
        }
        @media (max-width: 600px) {
          max-width: 400px;
        }
        .payment_types_container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1em;
          a {
            text-decoration: none;
            color: #fff;
          }
          .paypal_way {
            width: 95%;
            border-radius: 0.6em;
            height: 50px;
            background-color: #ffc439;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .card_way,
          .crypto_way {
            width: 95%;
            height: 50px;
            background: #2997ff;
            color: #fff;
            font-weight: 700;
            padding: 1em 2em;
            border-radius: 1em;
            text-align: center;
            border-radius: 0.6em;
            cursor: pointer;
            &:hover {
              opacity: 0.9;
            }
          }
          .card_way {
            span {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0.7em;
              border-radius: 0.6em;
            }
            svg {
              font-size: 1.2rem;
            }
          }
          .crypto_way {
            border-radius: 0.6em;
            background: rgb(34, 34, 34);
          }
        }
        .heading {
          display: flex;
          align-items: center;
          width: 100%;
          font-weight: bold;
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+/Edge */
          user-select: none; /* Standard */
          .round_number {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            margin: 10px 0;
            background: #d4d9ff;
            color: #3d3c76;
            border-radius: 3em;
            box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
              0px 2px 5px 0px rgba(50, 50, 93, 0.1),
              0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
          }
          h2 {
            font-size: 1.5rem;
            font-weight: bold;
            margin-left: 0.4em;
          }
        }
        .signedin_cont {
          width: 80%;
          margin: 0 auto;
          height: 80px;

          h2 {
            font-size: 1.5rem;
            font-weight: 400;
            padding-top: 0.5em;
            color: #2997ff !important;
            margin-left: 0.7em;
          }
        }
        .signin_cont {
          width: 100%;
          height: 200px;
        }

        .checkout_form {
          width: 100%;

          align-self: center;
          font-family: "Montserrat", sans-serif;

          // #card-element {

          // }
          .card_input {
            background: #d4d9ff;

            box-sizing: border-box;
            width: 100%;
            height: 50px;
            display: block;
            margin-top: 1em;
            padding: 0.9rem 0.95rem 0.8rem;

            border-radius: 0.6em;
            border: 1px solid #d2d2d7;
            font-size: 1.1rem;
            font-family: "Montserrat", sans-serif;
            font-weight: 400;
          }
        }

        .result_message {
          line-height: 22px;
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+/Edge */
          user-select: none; /* Standard */
          font-size: 16px;
        }

        .hidden {
          display: none;
        }

        #card-error {
          color: rgb(211, 74, 56);

          font-size: 16px;

          line-height: 20px;

          margin-top: 12px;

          text-align: center;
        }

        #payment-request-button {
          margin-bottom: 32px;
        }

        /* Buttons and links */

        .btn_pay {
          background: #dfabff;

          font-family: Arial, sans-serif;
          margin-top: 1em;
          color: #3d3c76;

          border-radius: 0.61em;

          border: 0;

          padding: 12px 16px;

          font-size: 16px;

          font-weight: 600;

          cursor: pointer;

          display: block;

          transition: all 0.2s ease;

          height: 50px;

          box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);

          width: 100%;
        }

        .btn_pay:hover {
          filter: contrast(115%);
        }

        .btn_pay:disabled {
          opacity: 0.5;

          cursor: default;
        }

        /* spinner/processing state, errors */

        .spinner,
        .spinner:before,
        .spinner:after {
          border-radius: 50%;
        }

        .spinner {
          color: #ffffff;

          font-size: 22px;

          text-indent: -99999px;

          margin: 0px auto;

          position: relative;

          width: 20px;

          height: 20px;

          box-shadow: inset 0 0 0 2px;

          -webkit-transform: translateZ(0);

          -ms-transform: translateZ(0);

          transform: translateZ(0);
        }

        .spinner:before,
        .spinner:after {
          position: absolute;

          content: "";
        }

        .spinner:before {
          width: 10.4px;

          height: 20.4px;

          background: #2997ff;

          border-radius: 20.4px 0 0 20.4px;

          top: -0.2px;

          left: -0.2px;

          -webkit-transform-origin: 10.4px 10.2px;

          transform-origin: 10.4px 10.2px;

          -webkit-animation: loading 2s infinite ease 1.5s;

          animation: loading 2s infinite ease 1.5s;
        }

        .spinner:after {
          width: 10.4px;

          height: 10.2px;

          background: #2997ff;

          border-radius: 0 10.2px 10.2px 0;

          top: -0.1px;

          left: 10.2px;

          -webkit-transform-origin: 0px 10.2px;

          transform-origin: 0px 10.2px;

          -webkit-animation: loading 2s infinite ease;

          animation: loading 2s infinite ease;
        }

        @keyframes loading {
          0% {
            -webkit-transform: rotate(0deg);

            transform: rotate(0deg);
          }

          100% {
            -webkit-transform: rotate(360deg);

            transform: rotate(360deg);
          }
        }

        @media only screen and (max-width: 600px) {
          form {
            width: 80vw;
          }
        }
        .checkout_type {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 50px;
          width: 50%;
          margin: 0.5em auto 0;
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+/Edge */
          user-select: none; /* Standard */
          justify-content: space-between;
          .logo {
            width: 50px;
          }
          span {
            margin-bottom: 0.3em;
            width: 120px;
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            align-items: flex-end;
            .gpay {
              height: 30px;
              color: white;
            }
            .apay {
              height: 40px;
            }
          }
        }
      }
    }
  }
}
