.footer-container {
  margin-top: 2em;

  background-color: #3d3c76;
  max-height: 400px;
  min-height: 280px;
  height: auto;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1em;
  @media (max-width: 70em) {
    //phone
  }
  .footer-text {
    width: 80%;
    font-size: 1rem;
    @media (max-width: 70em) {
      //phone
      font-size: 0.5rem;
    }
  }
  .footer-copyright {
    font-weight: bold;
    font-weight: 1.2rem;
    @media (max-width: 70em) {
      //phone
      font-size: 0.6rem;
    }
  }
}
