@use "../../../styles/" as *;

.chat {
  display: flex;
  flex-direction: column;
  position: absolute;

  bottom: 3.5em;
  right: 6em;
  border-radius: 0.6em;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #3d3c76;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9898ff;
    border-radius: 20px;
    /* border: 2px solid #1a1a1a; */
  }
  .switch {
    position: fixed;
    right: 0.8em;
    bottom: -0.2em;
    background: #d3eaff;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    // padding: 1em;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    font-size: 1.5rem;
    color: #18174a;
    &:hover .chat-hover {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 10;
      right: 90px;

      // background-color: #d3eaff;
      font-size: 0.8rem;
      font-weight: bold;
      color: #3d3c76;
      padding: 0.5em;
      border-radius: 0.3em;
      white-space: nowrap;
      opacity: 1;
      width: auto;
      transition: 300ms all ease-out;
      // transform: scale(1);
      box-shadow: inset 10em 0em #d3eaff;
      transform: translateX(10px);
    }
    .chat-hover {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 10;
      right: 90px;
      // background-color: #d3eaff;
      font-size: 0.8rem;
      font-weight: bold;
      color: #3d3c76;
      padding: 0.5em;
      border-radius: 0.3em;
      white-space: nowrap;
      opacity: 0;
      width: 0;
      // transform: scale(0);
      z-index: 100;
      box-shadow: inset 0em 0em #d3eaff;
      transform: translateX(0);
    }
    @media (max-width: 810px) {
      //phone
      display: none;
    }
    @media (max-height: 600px) {
      //phone
      display: none;
    }
  }
  .chat_container {
    box-shadow: 4px 5px 8px 5px rgba(0, 0, 0, 0.4);
    max-height: 75vh;

    padding: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-color: #3d3c76 #3d3c76;
    transition: scrollbar-color 100ms ease-in;

    background-color: #3d3c76;
    border: 5px solid #3d3c76;

    &.chat_container_active {
      height: 920px;
      width: 400px;

      // transition: 250ms all ease-in-out;
    }
    &.chat_container_inactive {
      height: 0px;
      width: 0px;

      // transition: 250ms all ease-in-out;
    }

    &.loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover {
      scrollbar-color: #18174a #3d3c76;
    }
  }
  form {
    height: 50px;
    width: 400px;
    min-height: 50px;
    display: flex;
    background: #d3eaff;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 0.6em 0.6em;

    input {
      width: 80%;
      height: 40px;

      padding: 0.6em;
      border: none;
      outline: none;
      color: $base-color;
      letter-spacing: 0.03em;
      display: flex;
      background: #d3eaff;
      border-radius: 5px;
    }
    button {
      width: 12%;
      height: 40px;
      margin-right: 0.2em;
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.3);
      border: none;
      padding: 0.3em;
      padding-top: 0.25em;
      font-size: 1.7rem;
      background-color: #18174a;
      color: #d3eaff;
      cursor: pointer;
      border-radius: 0.4em;
      transition: transform 200ms ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      &:active,
      &:focus {
        outline: none;
      }
      &:active {
        transform: scale(0.95);
        box-shadow: 0 0 1px 1.8px $base-blue;
      }
      .logo {
        // transform: rotate(45deg);
        font-size: 1.2rem;
      }
    }
  }
}
